// dittofeed.ts
import { DittofeedSdk, IdentifyData, ScreenData, TrackData } from '@dittofeed/sdk-web';
import { config } from "./config";

class DittofeedService {
    private instance: Awaited<ReturnType<typeof DittofeedSdk.init>> | null = null;
    private initializationPromise: Promise<void> | null = null;

    async initialize() {
        if (this.initializationPromise) {
            return this.initializationPromise;
        }

        this.initializationPromise = new Promise(async (resolve) => {
            try {
                // Keep the full write key including "Basic " prefix
                const writeKey = config.dittofeed.writeKey || '';

                this.instance = await DittofeedSdk.init({
                    writeKey,
                    host: config.dittofeed.host || 'https://dittofeed.equilityhq.com',
                });

            } catch (error) {
                console.error('Failed to initialize Dittofeed:', error);
                this.instance = null;
            }
            resolve();
        });

        return this.initializationPromise;
    }

    async screen(params: ScreenData) {
        await this.ensureInitialized();
        if (this.instance) {
            try {
                // Ensure timestamp is present
                const enhancedParams = {
                    ...params,
                    timestamp: params.timestamp || new Date().toISOString()
                };


                await DittofeedSdk.screen(enhancedParams);
            } catch (error) {
                console.error('Failed to send screen event:', error);
                throw error;
            }
        }
    }

    async track(params: TrackData) {
        await this.ensureInitialized();
        if (this.instance) {
            try {
                const enhancedParams = {
                    ...params,
                    timestamp: params.timestamp || new Date().toISOString()
                };

                await DittofeedSdk.track(enhancedParams);
            } catch (error) {
                console.error('Failed to send track event:', error);
                throw error;
            }
        }
    }

    async identify(params: IdentifyData) {
        await this.ensureInitialized();
        if (this.instance) {
            try {
                const enhancedParams = {
                    ...params,
                    timestamp: params.timestamp || new Date().toISOString()
                };

                await DittofeedSdk.identify(enhancedParams);
            } catch (error) {
                console.error('Failed to send identify event:', error);
                throw error;
            }
        }
    }

    async flush() {
        await this.ensureInitialized();
        if (this.instance) {
            try {
                await DittofeedSdk.flush();
            } catch (error) {
                console.error('Failed to flush events:', error);
                throw error;
            }
        }
    }

    private async ensureInitialized() {
        if (!this.instance && !this.initializationPromise) {
            await this.initialize();
        } else if (this.initializationPromise) {
            await this.initializationPromise;
        }
    }
}

export const Dittofeed = new DittofeedService();